<template>
  <div class="responsive-bar">
    <Footer></Footer>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="scan-qr">
      <!-- Page -->
      <div class="back-icon">
        <img src="@/assets/previous.svg" width="30" @click="$router.back()" />
      </div>
      <div class="header-text">
        <div class="topic">
          {{ $t("home.asset_graph") }}
        </div>
      </div>
      <div class="card-form-login">
        <div class="text-start d-flex flex-wrap">
          <p>{{ $route.query.bn }}</p>
          <p class="mx-2">></p>
          <p>{{ $route.query.fn }}</p>
          <p class="mx-2">></p>
          <p>{{ $route.query.rn }}</p>
          <p class="mx-2">></p>
          <p>{{ $route.query.cn }}</p>
          <p class="mx-2">></p>
          <p>{{ $route.query.scn }}</p>
        </div>
        <div class="sub-menu">
          <div class="input-group">
            <span
              class="input-group-text"
              id="basic-addon1"
              style="border-radius: 10px 0 0 10px"
            >
              <!-- @click="resetSearch" -->
              <img src="@/assets/g-search.svg" width="20px" />
            </span>

            <input
              type="text"
              class="form-control filter-box"
              style="
                      border-right-style: none;
                      margin-right: 1rem;
                      border-radius: 0 10px 10px 0;
                    "
              v-debounce:700ms="searchByText"
              :placeholder="$t('home.search')"
              v-model="searchText"
            />
          </div>
          <p class="text-start px-3" style="font-size: 14px; color: #7c7c7c;">
            {{ totalRows }} {{ $t("asset.total_items") }}
          </p>
        </div>

        <div class="asset-box" v-if="fixedAssetList.length > 0">
          <!-- Detail Each Asset -->
          <div
            v-for="asset in fixedAssetList"
            :key="asset.id_asset"
            class="item-asset"
            @click="onAssetClick(asset)"
          >
            <Lazyloadimage
              :image="checkImage(asset.image_url)"
              :holderimg="holderimg"
              v-if="asset.image_url"
            ></Lazyloadimage>
            <img v-else class="banner" :src="noimg" alt="" />

            <div class="detail-asset">
              <p class="asset-id">
                {{ asset.id_fixed_asset }}
              </p>
              <p class="asset-detail">{{ asset.name }}</p>
              <p class="asset-detail">{{ asset.description || "-" }}</p>
            </div>
          </div>

          <!-- End Here -->
        </div>
        <div
          class="d-flex justify-content-center"
          style="margin-top: 25%"
          v-else
        >
          <h1 class="">{{ $t("asset.dt_not") }}</h1>
        </div>
      </div>
      <div
        class="d-flex justify-content-center mt-3"
        v-if="fixedAssetList.length > 0"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
      <TotalPageCount
        v-if="fixedAssetList.length > 0"
        :page="currentPage"
        :perPage="perPage"
        :currentAmount="fixedAssetList.length"
        :total="totalRows"
      />
      <div class="line-break"></div>
    </div>
  </div>
</template>

<script>
// import Background from "@/components/Background.vue";
import axios from "axios";
import { authHeader } from "@/store/actions";
import Footer from "@/components/Footer.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Lazyloadimage from "@/components/Lazyloadimage.vue";
import TotalPageCount from "@/components/TotalPageCount.vue";

// ? -> back to top button from lib -> https://github.com/caiofsouza/vue-backtotop

export default {
  data() {
    return {
      page: null,
      currentPage: 1,
      perPage: 24,
      totalRows: 0,
      isLoading: false,
      searchText: "",
      company_id: null,
      filterOptions: {
        text: "",
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_room: null,
        // department: null,
        // zone: null,
        // dateUsed_start: null,
        // dateUsed_end: null,
        // datePurchase_min: null,
        // datePurchase_max: null,
        // useful_life_min: "",
        // useful_life_max: "",
        // purchase_price_min: "",
        // purchase_price_max: "",
        // acc_depreciation_min: "",
        // acc_depreciation_max: "",
        // net_book_min: "",
        // net_book_max: "",
      },
      saveToStore: false,
      noimg: require("@/image/Not-regis-qr-code.png"),
      holderimg: require("@/image/Pic_Loading.jpg"),
      fixedAssetList: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    const id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.company_id = id_company;
    this.filterOptions.id_location_building = this.$route.query.bid;
    this.filterOptions.id_location_floor = this.$route.query.fid;
    this.filterOptions.id_location_room = this.$route.query.rid;
    this.filterOptions.id_category = this.$route.query.cid;
    this.filterOptions.id_sub_category = this.$route.query.scid;

    await this.loadFixedAssetList();
    this.isLoading = false;
  },
  methods: {
    moment,
    checkImage(image) {
      if (typeof image === "string") {
        return JSON.parse(image).url;
      } else {
        return image.url;
      }
    },
    async loadFixedAssetList() {
      this.isLoading = true;
      await this.$store.dispatch("filterAllAsset_pagination", {
        filterOptions: this.filterOptions,
        saveToStore: this.saveToStore,
        countingStatus: this.countingStatus,
        company_id: this.company_id,
        page: this.currentPage,
        limit: this.perPage,
      });
      this.totalRows = this.assets_count;
      this.fixedAssetList = this.getAllAssets;
      this.isLoading = false;
    },
    searchByText() {
      this.filterOptions.text = this.searchText;
      this.loadFixedAssetList();
    },

    async onAssetClick(asset) {
      this.$router.push(`/detailOneAsset/${asset.id_asset}`);
    },
  },
  watch: {
    async currentPage() {
      this.isLoading = true;
      try {
        const id_company = await this.$store.dispatch("getCompanyIdCookie");
        this.company_id = id_company;
        await this.loadFixedAssetList();

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      counting_date: "getCountingDate",
      getAllAssets: "getAllAssets",
      getUserDetail: "getUserDetail",
      getAllAssetsStore: "getAllAssetsStore",
      summaryData: "getSummaryData",
      assets_count: "assets_count",
      getAllAssetsFilter: "getAllAssetsFilter",
      getDetailAsset: "getDetailAsset",
      isRoleLower: "isRoleLower",
      optionChoice: "getfilterOption",
      permissionStatus: "permissionStatus",
      getPageState: "getPageState",
    }),
  },
  components: {
    Footer,
    Loading,
    TotalPageCount,
    Lazyloadimage,
  },
};
</script>

<style lang="scss" scoped>
.icon-back {
  top: 22px;
  right: 20px;
  // top: -1;
  display: flex;
  width: 30px;
  // float: right;
  margin-bottom: 24px;
  position: absolute;
}
.b-find {
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  margin: auto !important;
  margin-top: 1rem !important;
  width: 50% !important;
  background: $background-gradient-btn-blue !important;
  z-index: 1000;
  -webkit-appearance: none !important;
}
.filter-block {
  width: 100vw;
  // height: 100vh;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  // padding: 1.5rem;
}

.filter-block-inside {
  width: 90%;
  // margin: auto;
  margin-top: 1rem;
  // margin-left: 1rem;
  display: grid;
  gap: 1rem 1rem;
}

.filter-input-group {
  // width: 30%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
  label {
    font: normal normal normal 11px/18px Mitr;
    color: $font-grey;
    padding: 0.4rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#basic-addon1 {
  height: 45px;
  border: none;
  background-color: #e0e0e0;
}
select {
  padding-left: 0.5rem !important;
}

.filter {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-radius: 10px;
  // border: 1px solid #007bff;
  background-color: white;
  margin: 0 !important;

  img {
    width: 40px;
    padding: 0.3rem;
  }
}

.filter-box {
  height: 45px;
  border: 0px;
  background-color: #e0e0e0;
}

.input-group {
  // border-right-style: none;
  // width: 80%;
  // height: 38px;
  margin: 0 auto;
  display: flex;
  // padding: 0;
  // margin-top: 60px;
}

.input-group label {
  justify-self: start;
  padding-bottom: 0.3rem;
  margin-bottom: 0 !important;
  color: #7c7c7c;
}
input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

.asset-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  // grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  word-wrap: break-word;
}

.banner {
  width: 70px;
  height: 70px;
  z-index: 10;
  object-fit: cover;
  border: #c4c4c4 2px solid;
  border-radius: 15px;
  margin: 0 auto;
}

.item-asset {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2px;
  // width: 100%;
  margin: 0 auto;
}

@media only screen and (min-width: 375px) {
  .banner {
    height: 90px;
    width: 90px;
  }
}
@media only screen and (min-width: 440px) {
  .banner {
    height: 130px;
    width: 130px;
  }
}
@media only screen and (min-width: 640px) {
  // .item-asset {
  //   width: 70%;
  // }
  .banner {
    height: 140px;
    width: 140px;
  }
}
@media only screen and (min-width: 768px) {
  .banner {
    height: 180px;
    width: 180px;
  }
}

@media only screen and (min-width: 1024px) {
  .asset-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  // .card-form {
  //   width: 700px !important;
  // }
  .banner {
    width: 200px;
    height: 200px;
    z-index: 10;
    object-fit: cover;
  }
}

.icon-back-to-top {
  width: 35px;
  height: 35px;
  background: $color-active-blue;
  padding: 0.5rem;
  border-radius: 2px;

  img {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

.btn-to-top {
  width: 30px;
  height: 30px;
  font-size: 22px;
  line-height: 22px;
  background: #007afe 0% 0% no-repeat padding-box;
}
.detail-seemore {
  font-size: 11px;
  padding-top: 5px;
  color: #271a1a;
}

.detail-asset {
  @extend .banner;
  padding-top: 1rem;
  font-size: 16px;
  text-align: left;
  margin: 0 auto;
  border: none;
  height: auto;

  .asset-id {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  .asset-detail {
    @extend .asset-id;
    color: $font-grey;
    word-break: break-all;
  }
}

.sub-menu {
  color: $font-grey;
  // padding-bottom: 2rem;
  // display: flex;
  // grid-template-columns: 1fr;
  // grid-gap: 0.5rem;
  // justify-content: center;
}

// select {
//   color: $color-active-blue;
//   background: white;
//   box-shadow: 0px 3px 6px #00000029;
//   border: none;
//   padding: 0.2rem 0.5rem 0.2rem 0.2rem;
//   border-radius: 15px;
//   font-size: 11px;
// }

.sub-menu button {
  margin-right: 1rem;
  cursor: pointer;
}

input {
  width: 100%;
  border-radius: 10px;
  // border: 1px solid grey;
  padding: 0.4rem;
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
::-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-ms-input-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}
:-moz-placeholder {
  padding: 0 0.5rem 0 0.5rem;
}

.btn-seemore {
  margin-top: 1rem;
  border-radius: 12px;
  font-size: 14px;
  padding: 0.3rem 1rem;
}

.btn-category {
  background: white;
  // color: #007afe;
  border-radius: 15px;
  padding: 0rem 2rem 0rem 2rem;
}

.detail {
  font-size: 11px;
  color: #7c7c7c;
}

.header-text {
  padding-top: 30px;
  height: 173px;
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: auto;

  h1 {
    font-size: 28px;
    font: normal normal bold 44px/48px Mitr;
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 0px;
  }
  p {
    font-size: 18px;
    color: #fff;
  }
}

.remind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #e0e0e0;
}

#reminder {
  font-size: 18px;
  margin: auto;
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.inRemind {
  display: flex;
  align-items: center;
  height: 37px;
  text-align: center;
  background-color: #d2e2f8;
}

#inReminder {
  font-size: 18px;
  margin: auto;
  color: #434343;
}

.card-form {
  width: 80%;
  padding-top: 50px;
  // padding: 2rem 1.5rem 2.5rem 1.5rem;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin-top: 8%;
  margin-bottom: 10%;
  height: 530px;
  overflow: hidden;
}

.card-form-login {
  margin: 5%;
  margin-bottom: 0px;
}

#button-addon2 {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  // border: 1px solid #7c7c7c;
  img {
    width: 40px;
    padding: 0.3rem !important;
  }
}
@media only screen and (min-width: 767px) and (max-width: 1025px) {
  .card-form {
    width: 85%;
    // padding: 2rem 1.5rem 2.5rem 1.5rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin-top: 10%;
    margin-bottom: 17%;
    height: 530px;
    overflow: hidden;
  }
  .filter-block-inside {
    width: 90%;
    // margin: auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}

@media only screen and (min-width: 1025px) {
  .sub-menu {
    // padding-bottom: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .card-form {
    padding-top: 30px;
    height: 530px;
    left: 0%;
    right: 0%;
    top: 5%;
    bottom: 10%;
    overflow: hidden;
  }

  .filter-block-inside {
    width: 90%;
    // margin: auto;
    margin-top: 1rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .card-form {
    width: 85%;
    height: 530px;
    padding-top: 50px;
    // padding: 2rem 1.5rem 2.5rem 1.5rem;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin-top: 10%;
    overflow: hidden;
  }
  .filter-block-inside {
    // width: 90%;
    // margin: auto;
    // margin-top: 1rem;
    // margin-bottom: 2rem;
    // margin-left: 1rem;
    display: grid;
    gap: 1rem 1rem;
  }
}

.btn-login {
  // margin-left: 2rem;
  // padding: 0.55rem 2.75rem 0.55rem 2.75rem;
  // background: transparent linear-gradient(180deg, #007bff 0%, #003e80 100%) 0%
  //   0% no-repeat padding-box;
  width: 200px;
  height: auto;
  color: white;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  outline: none;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: $background-gradient-btn-blue;
}

.btn-regist {
  width: 200px;
  height: auto;
  padding: 0.5rem 12px 0.5rem 12px;
  border-radius: 10px;
  color: $color-active-blue;
  box-shadow: $shadow-default;
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(180deg, #007afe 0%, #013f81 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.p-clear {
  color: grey;
  font-size: 14px;
  margin-top: 1.5rem;
  padding-right: 2rem;
  cursor: pointer;
  text-decoration: underline;
}

.p-clear:hover {
  color: black;
  transition: 0.2s;
}

.filter-count {
  border: 2px solid #007afe;
  border-radius: 5px;
}

.filter-count-box {
  color: #007afe;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-border {
  background-color: transparent !important;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style {
  background-color: white;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

button.cancel {
  margin-right: 10px;
  // margin: 15px 10px 20px 30px;
  padding: 0px;
  border-radius: 10px;
  border-color: transparent;
  color: #fff;
  background: #7c7c7c;
}

.counting-status-select {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #015fc3 !important;
  border-radius: 5px;
  background-color: #fff !important;
  color: #015fc3 !important;
  background-image: url("../../../assets/down-arrow-blue.svg") !important;
}

.back-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 412px) {
  .back-icon {
    padding: 10px;
    cursor: pointer;
  }
}
</style>
